import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  offcanvasTopAnimation,
  slideInAnimation,
} from 'src/app/animations/route-animation';
import { StorageService } from './../../services/storage.service';
import { MatSidenav } from '@angular/material/sidenav';
import { PatientService } from 'src/app/services/patient.service';
import { ISector } from 'src/app/models/sector';
import { IBed } from 'src/app/models/patient';
import { ToggleFullScreenService } from 'src/app/services/toggle-full-screen.service';
import { FormBuilder } from '@angular/forms';
import { BodyJson } from 'src/app/services/http.service';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { CONFIG_SISTEM } from 'src/app/constants/config';
import { AuthService } from 'src/app/services/auth.service';
import { IConfig } from 'src/app/models/utils';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [slideInAnimation, offcanvasTopAnimation],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() colapse: 'vertical' | 'horizontal' = 'vertical';
  @ViewChild('navbar') navbar: ElementRef<HTMLElement> | undefined;
  @ViewChild('drawer') drawer: MatSidenav = {} as MatSidenav;

  constructor(
    private storage: StorageService,
    private authService: AuthService,
    private patientService: PatientService,
    private toggleFullScreenService: ToggleFullScreenService,
    private fb: FormBuilder,
    private titleService: Title
  ) {
    this.setConfig();
  }

  loading = false;

  isFullScreen = false;

  isOpen = false;
  sectorList: ISector[] = [];
  bedList: IBed[] = [];

  filter_form = this.fb.group({
    sector: [''],
    seconds: [15]
  });

  currentConfig: IConfig = {
    logo: 'assets/images/logo.png'
  } as IConfig;

  ngOnInit(): void {
    const secund = localStorage.getItem('SECUND')
    if(secund){
      this.filter_form.patchValue({
        seconds: Number(secund ?? 0),
      });
    }

    this.getSectorsAndBeds();

    // Garantir que o valor inicial seja definido corretamente
    this.filter_form.patchValue({
      sector: '', // Garante que a opção "Todos" esteja selecionada
    });

    this.filter_form.controls.seconds.valueChanges
    .pipe(startWith(''), debounceTime(500), distinctUntilChanged())
    .subscribe((value) => {
    if(value && Number(value) > 0) this.storage.changeSecund(Number(value))
    });
  }

  ngOnDestroy(): void {
    this.storage.unwatchUser();
  }

  filterSubmitHandler(sector?: BodyJson) {
    sector = sector || {};

    setTimeout(() => {
      this.patientService.changeSector(sector as unknown as ISector);
    }, 100);
  }

  async handleToggleNavbar() {
    this.isFullScreen = await this.toggleFullScreenService.toggleFullScreen(
      this.isFullScreen
    );
  }

  setConfig() {
    const url = window.location.hostname; // Obtém o domínio atual
    if(url === 'localhost') return;

    const host = url.split('.skinone')[0].split('paineltv')[1]

    this.authService.config(host).subscribe({
      next: (data) => {
        this.setConfigSystem(data)
      },
    });
  }

  getSectorsAndBeds() {
    this.patientService.getPatientSectorsAndBeds().subscribe({
      next: (data) => {
        this.sectorList = data.sectors;
      },
      error: (error) =>{
        if (error?.status === 401) {
          this.storage.logout();
        }
      }
    });
  }

  get serviceFormValue() {
    const value = this.filter_form.get('sector')?.value;
    return typeof value === 'string' ? value : '';
  }

  displayFn(deparment: { name: string }): string {
    return deparment && deparment.name ? deparment.name : 'Todos';
  }

  drawerToggle() {
    this.drawer.toggle();
    setTimeout(() => {
      dispatchEvent(new Event('resize'));
    }, 300);
  }

  logout() {
    this.storage.unwatchUser();
    this.storage.logout();
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange(event: Event): void {
    this.isFullScreen = !!document.fullscreenElement;
  }

  setConfigSystem(config: IConfig){
    
    if (config) {
      this.currentConfig = config;

      // Atualiza o título da aba
      this.titleService.setTitle(config.name);

      // Atualiza o favicon
      // this.updateFavicon(config.logo);
    }
  }

  private updateFavicon(iconUrl: string): void {
    let link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'png';
      document.head.appendChild(link);
    }
    link.href = iconUrl;
  }
  
  getLogo(): string {
    return this.currentConfig.logo;
  }
}
