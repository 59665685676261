import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IToken } from '../models/user';
import { HttpParams } from '@angular/common/http';
import { IConfig } from '../models/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpService) {}

  login(email: string, password: string) {
    const body = new HttpParams()
      .set('email', email)
      .set('password', password);

    return this.http.post<IToken>('adminweb/auth-admin/', body);
  }

  config(initial: string) {
    const params = new HttpParams()
      .set('initial', initial);

    return this.http.get<IConfig>('https://iahealthgeneral.skinone.com.br/core/hospital/', params, {token: false});
  }
}
